import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { Section, Container, Columns } from 'react-bulma-components'

function ThankYouComponent() {
  return (
    <Section className="">
      <Container>
        <Columns className="is-centered">
          <Columns.Column className="is-9-desktop is-10-tablet is-12-mobile has-text-centered content">
            <h2 className="title">
              Thank you,{' '}
              <span className="has-text-success">we'll be in touch soon.</span>
            </h2>
            <p>
              Within regular UK business hours, expect to hear with us within{' '}
              <mark>20 minutes</mark>.
            </p>
          </Columns.Column>
        </Columns>

        <div className="mt-3">
          <Columns className="is-inline-flex">
            <Columns.Column className="is-3 is-offset-1">
              <StaticImage
                src="https://www.datocms-assets.com/20955/1579120416-jenny.jpg?w=300&fit=facearea&facepad=5&mask=ellipse"
                alt="Jenny - Pocketworks"
                placeholder="tracedSVG"
                loading="lazy"
              />
            </Columns.Column>
            <Columns.Column className="is-6 ">
              <br />
              <br />
              <br />
              <p className="is-size-4">
                "My name is Jenny and I'll help you understand how Pocketworks
                can create a successful app for your organisation."
              </p>
              <p className="tag is-medium">
                Jenny, Sales &amp; Business Support
              </p>
              <p className="mt-5">
                <Link
                  className="button is-primary is-leafy is-medium"
                  to="/about/blog"
                >
                  Got a minute? Check out our blog
                </Link>
              </p>
            </Columns.Column>
          </Columns>
        </div>
      </Container>
    </Section>
  )
}

export default ThankYouComponent
